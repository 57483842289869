.actionColor {
    color: rgba(26,193,123,1);
}

.filterAreaSpacing {
    margin-bottom: 20px;
}

.searchButton {
    margin-top: 13px;
}

.modalDivider {
    margin-top: 5px;
    margin-bottom: 10px;
}

.cardRoot {
    margin-bottom: 10px;
    background-color: transparent;
    flex-grow: 1;
}

.cardTitle {
    font-size: 16;
}

.grayStyleLevel1 {
    color: #a6a6a6;
}

.grayStyleLevel2 {
    color: #676565;
}

.grayStyleBackground {
    background-color: #bebebe;
}

.highlightSelected {
    background-color: #a3e2ff;
}

.inactiveBackground {
    background-color: pink;
}

.nomralWhiteBackground {
    background-color: #ffffff;
}

.categoryBreadcrumb {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}