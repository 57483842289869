.root {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headerRow {
    display: flex;
    flex-direction: row;
}