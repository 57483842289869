body {
  margin: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiLink-root {
  text-decoration: none;
}

.MuiLink-root:hover {
  text-decoration: underline;
}

.MuiDialogTitle-root {
  padding-bottom: 0px;
}

.upload-list-item-success {
  margin-bottom: 3px;
  margin-top: 3px;
  color: green;
}

.file-upload-err {
  background-color: rgb(218, 23, 88);
  color: white;
}

.file-from-db {
  background-color: rgb(88, 193, 235);
  color: white;
}

.file-new-upload {
  color: black;
}

.ant-upload-list-item {
  padding-bottom: 5px;
}

.ant-upload-list-picture .ant-upload-list-item-name {
  font-size: 20px;
  /* overflow-wrap: break-word; */
  /* word-break: break-all; */
  inline-size: 150px;
}

.ant-btn-icon-only, .ant-btn-icon-only.ant-btn-sm, .ant-btn-icon-only.ant-btn-sm > * {
  font-size: 20px;
}

.ant-tree {
  background: none !important;
  /* background-color: white; */
}

.ant-upload-list-picture-card-container {
  width: 162px !important;
  height: 162px !important;
}

.disabledColor {
  background-color: #757575;
  opacity: 0.5;
}

.searchHighlight {
  color:rgb(218, 23, 88);
  padding: 0 0 0 0px;
}